import cookie from 'js-cookie';
export var getCookieHost = function getCookieHost(host) {
  var dev = /^localhost/i;
  var staging = /^segment\.build$/i;
  var cdpweek = /^cdpweek\.com$/i;
  var subdomain = /^([a-zA-Z0-9]+)\.segment\.(build|com)$/;

  if (dev.test(host)) {
    return "localhost";
  }

  if (staging.test(host)) {
    return "segment.build";
  }

  if (cdpweek.test(host)) {
    return "cdpweek.com";
  }

  if (subdomain.test(host)) {
    var _subdomain$exec;

    return "segment.".concat((_subdomain$exec = subdomain.exec(host)) === null || _subdomain$exec === void 0 ? void 0 : _subdomain$exec[2]);
  }

  return "segment.com";
};
export var getCookie = function getCookie(key) {
  var value = "; ".concat(document.cookie);
  var parts = value.split("; ".concat(key, "="));

  if (parts.length === 2) {
    var _parts$pop;

    return (_parts$pop = parts.pop()) === null || _parts$pop === void 0 ? void 0 : _parts$pop.split(";").shift();
  }
};
export var setCookie = function setCookie(name, value) {
  var minutes = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 30;
  var date = new Date();
  date.setTime(date.getTime() + minutes * 60 * 1000);
  var expires = "; expires=".concat(date.toUTCString(), "; domain=").concat(getCookieHost(window.location.host), ";");
  var cookie = "".concat(name, "=").concat(value || "").concat(expires, " path=/;");
  document.cookie = cookie;
};
export var remove = function remove(name) {
  if (cookie.get(name)) {
    cookie.remove(name, {
      domain: getCookieHost(window.location.host)
    });
    return !cookie.get(name);
  }

  return false;
};