import { ANON, identifyE2E } from './helpers';
import { purgePIIFromLocalStorage } from './util';

var addClearTraitsMiddleware = function addClearTraitsMiddleware() {
  var analytics = window.analytics;
  if (!analytics) return;
  analytics.addSourceMiddleware(function (_ref) {
    var payload = _ref.payload,
        next = _ref.next;
    purgePIIFromLocalStorage();
    next(payload);
  });
};

var overridePageContext = function overridePageContext(page) {
  var analytics = window.analytics;
  if (!analytics) return; // Middleware that overrides default page properties added automatically by analytics.
  // https://segment.com/docs/connections/spec/common/#context-fields-automatically-collected

  analytics.addSourceMiddleware(function (_ref2) {
    var payload = _ref2.payload,
        next = _ref2.next;

    for (var _key in page) {
      payload.obj.context.page[_key] = page[_key];
    }

    next();
  });
};

var loadAnalytics = function loadAnalytics(writeKey, options) {
  var _window$analytics;

  var integrations = options.integrations,
      context = options.context;

  if (context !== null && context !== void 0 && context.page) {
    overridePageContext(context.page);
  }

  addClearTraitsMiddleware();
  (_window$analytics = window.analytics) === null || _window$analytics === void 0 ? void 0 : _window$analytics.load(writeKey, {
    integrations: integrations
  });

  if (window.analytics && identifyE2E(window.location.host)) {
    window.analytics.setAnonymousId(ANON);
  }
};

export { loadAnalytics };