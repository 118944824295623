var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"-FKpHfqKu2QG0j2G_WVBA"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from '@sentry/nextjs'

const trackingUrls = [
  `/the-cdp-report/`,
  `/the-cdp-report/2023/`,
  `/customer-engagement-stack/`,
  `/g2-grid-report-cdp/`,
  `/report-2030-today/`,
  `/state-of-personalization-report-2022/`,
  `/state-of-personalization-report/`,
  `/the-growth-report-2022/`,
  `/the-growth-report/`,
]

Sentry.init({
  dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
  enabled: process.env.NODE_ENV !== 'development',
  tracesSampleRate: 0,

  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,

  // We're sending events to Sentry only from trackingUrls list.
  beforeSend(event) {
    if (trackingUrls.find((url) => new RegExp(`${url}$`).test(event.request.url))) {
      return event
    }
  },
})
