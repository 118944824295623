import _objectWithoutProperties from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
var _excluded = ["children"];
import './polyfill';
import { Global, ThemeProvider, CacheProvider } from '@emotion/react';
import { LazyMotion, MotionConfig } from 'framer-motion';
import { useEffect, useMemo } from 'react';
import { useRouter } from 'next/router';
import { Hydrate, QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import theme, { Normalize, Fonts, whitneyFont } from '@segment/matcha/foundations/Theme';
import { FontProvider } from '@segment/matcha/foundations/FontContext/FontContext';
import { setupPreferences, DOMAIN } from '@segment/templates/TrustArcConsentManager/utils';
import { useTracking } from '@segment/tracking';
import { useTrustArc } from '@segment/tracking/useTrustArc';
import domMax from '@segment/utils/framer/domMax';
import emotionCache from '@segment/utils/emotionCache';
import { LocaleProvider } from '@segment/contexts/locale';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";

var usePageLoadTracking = function usePageLoadTracking() {
  var _useTracking = useTracking(),
      rehydrateFirstUrlCookies = _useTracking.rehydrateFirstUrlCookies,
      rehydrateUtmCookies = _useTracking.rehydrateUtmCookies;

  var _useRouter = useRouter(),
      pathname = _useRouter.pathname,
      query = _useRouter.query;

  useEffect(function () {
    rehydrateFirstUrlCookies();
    rehydrateUtmCookies(); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, query]);
};

var SharedProviders = function SharedProviders(_ref) {
  var children = _ref.children,
      props = _objectWithoutProperties(_ref, _excluded);

  var queryClient = useMemo(function () {
    return new QueryClient();
  }, []);
  var isCypress = useMemo(function () {
    return Boolean(true && window.Cypress);
  }, []);
  usePageLoadTracking();
  useTrustArc(setupPreferences, DOMAIN);
  return _jsx(LocaleProvider, {
    lang: process.env.NEXT_PUBLIC_SITE_LANG || "en-US",
    children: _jsx(CacheProvider, {
      value: emotionCache,
      children: _jsx(ThemeProvider, {
        theme: theme,
        children: _jsx(FontProvider, {
          font: whitneyFont,
          children: _jsx(MotionConfig, {
            reducedMotion: "user",
            children: _jsxs(LazyMotion, {
              features: domMax,
              strict: true,
              children: [_jsx(Global, {
                styles: Normalize
              }), _jsx(Fonts, {}), _jsx(QueryClientProvider, {
                client: queryClient,
                children: _jsxs(Hydrate, {
                  state: props.dehydratedState,
                  children: [children, !isCypress && _jsx(ReactQueryDevtools, {
                    initialIsOpen: false
                  })]
                })
              })]
            })
          })
        })
      })
    })
  });
};

export default SharedProviders;