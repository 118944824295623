import type { AppContext, AppProps } from 'next/app'
import App from 'next/app'

import type { PageConfig } from '@segment/contexts/page'
import { PageConfigProvider } from '@segment/contexts/page'

import type { PageProps } from '@segment/next-shared/Providers'
import SharedProviders from '@segment/next-shared/Providers'

const CustomApp = ({ Component, pageProps }: AppProps<PageProps>) => {
  const pageConfig: PageConfig = pageProps.page

  return (
    <SharedProviders dehydratedState={pageProps.dehydratedState}>
      <PageConfigProvider value={pageConfig}>
        <Component {...pageProps} />
      </PageConfigProvider>
    </SharedProviders>
  )
}

CustomApp.getInitialProps = async (appContext: AppContext) => {
  const appProps = await App.getInitialProps(appContext)
  const pageConfig: PageConfig = {
    basePath: process.env.BASE_PATH ?? ``,
    baseUrl: process.env.BASE_URL ?? ``,
    appUrl: process.env.APP_URL ?? ``,
    queryParamsFromCookie: ``,
  }

  return {
    ...appProps,
    pageProps: { page: pageConfig },
  }
}

export default CustomApp
