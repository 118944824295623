/**
 * Get the path from a URL
 * @param url - The URL
 * @returns The path
 * @example
 * const url = `https://www.example.com/some/path`
 * const result = getUrlPath(url) // `/some/path`
 */
var getUrlPath = function getUrlPath(url) {
  if (!url) return "";
  var urlObj = new URL(url);
  return urlObj.pathname;
};

export default getUrlPath;