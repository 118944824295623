import _defineProperty from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
import _objectWithoutProperties from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/objectWithoutProperties.js";
var _excluded = ["values", "unit", "step"];

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

var getPrefix = function getPrefix(prependMedia) {
  return prependMedia ? "@media " : "";
};

export var createBreakpoints = function createBreakpoints(breakpoints) {
  var values = breakpoints.values,
      _breakpoints$unit = breakpoints.unit,
      unit = _breakpoints$unit === void 0 ? "px" : _breakpoints$unit,
      _breakpoints$step = breakpoints.step,
      step = _breakpoints$step === void 0 ? 5 : _breakpoints$step,
      other = _objectWithoutProperties(breakpoints, _excluded);

  var keys = Object.keys(values);

  var up = function up(key) {
    var prependMedia = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
    var predefinedValue = values[key];
    var value = typeof predefinedValue === "number" ? predefinedValue : key;
    return "".concat(getPrefix(prependMedia), "(min-width:").concat(String(value)).concat(unit, ")");
  };

  var down = function down(key) {
    var prependMedia = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
    var endIndex = keys.indexOf(key) + 1;
    var upperbound = values[keys[endIndex]];

    if (endIndex === keys.length) {
      // Down from the biggest breakpoint applies to all sizes
      return up(0);
    }

    var value = typeof upperbound === "number" && endIndex > 0 ? upperbound : key;
    return "".concat(getPrefix(prependMedia), "(max-width:").concat(value - step / 100).concat(unit, ")");
  };

  var between = function between(start, end) {
    var prependMedia = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : true;
    var endIndex = keys.indexOf(end);

    if (endIndex === keys.length - 1) {
      return up(start);
    }

    var predefinedValue = values[keys[endIndex + 1]];
    return "".concat(getPrefix(prependMedia), "(min-width:").concat(typeof values[start] === "number" ? String(values[start]) : String(start)).concat(unit, ") and ") + "(max-width:".concat((endIndex !== -1 && typeof predefinedValue === "number" ? predefinedValue : end) - step / 100).concat(unit, ")");
  };

  var only = function only(key) {
    var prependMedia = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
    return between(key, key, prependMedia);
  };

  function width(key) {
    return values[key];
  }

  return _objectSpread({
    keys: keys,
    values: values,
    up: up,
    down: down,
    between: between,
    only: only,
    width: width
  }, other);
};