import _defineProperty from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

import { createBreakpoints } from './createBreakpoints';
import createTypography from './createTypography';
import './typings/emotion.d.ts';
var breakpoints = createBreakpoints({
  values: {
    xs: 0,
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200,
    xxl: 1400,
    xxxl: 1700
  },
  unit: "px"
});
export var WHITNEY_FONT = "Whitney, sans-serif";
export var ROBOTO_MONO_FONT = "\"RobotoMono\", monospace";
var homeTypography = createTypography({
  typography: {
    homeH1Hero: {
      fontFamily: "var(--fontFamily, ".concat(WHITNEY_FONT, ")"),
      fontWeight: 700,
      fontSize: "40px",
      lineHeight: 1.3,
      letterSpacing: "-1.2px",
      overrides: [{
        fontSize: "56px",
        lineHeight: 1.3,
        letterSpacing: "-1.68px"
      }, {
        fontSize: "64px",
        lineHeight: 1.3,
        letterSpacing: "-2.56px"
      }]
    },
    homeH1: {
      fontFamily: "var(--fontFamily, ".concat(WHITNEY_FONT, ")"),
      fontWeight: 700,
      fontSize: "34px",
      lineHeight: 1.25,
      letterSpacing: "-1.02px",
      overrides: [{
        fontSize: "48px",
        lineHeight: 1.2,
        letterSpacing: "-1.44px"
      }, {
        fontSize: "56px",
        lineHeight: 1.1,
        letterSpacing: "-2.24px"
      }]
    },
    homeH2: {
      fontFamily: "var(--fontFamily, ".concat(WHITNEY_FONT, ")"),
      fontWeight: 700,
      fontSize: "32px",
      lineHeight: 1.3,
      letterSpacing: "-0.96px",
      overrides: [{
        fontSize: "40px",
        lineHeight: 1.3,
        letterSpacing: "-1.2px"
      }, {
        fontSize: "48px",
        lineHeight: 1.3,
        letterSpacing: "-1.92px"
      }]
    },
    homeH3: {
      fontFamily: "var(--fontFamily, ".concat(WHITNEY_FONT, ")"),
      fontWeight: 700,
      fontSize: "28px",
      lineHeight: 1.3,
      letterSpacing: "-0.84px",
      overrides: [{
        fontSize: "28px",
        lineHeight: 1.3,
        letterSpacing: "-0.84px"
      }, {
        fontSize: "32px",
        lineHeight: 1.3,
        letterSpacing: "-1.28px"
      }]
    },
    homeH4: {
      fontFamily: "var(--fontFamily, ".concat(WHITNEY_FONT, ")"),
      fontWeight: 700,
      fontSize: "24px",
      lineHeight: 1.5,
      letterSpacing: "-0.72px",
      overrides: [{
        fontSize: "24px",
        lineHeight: 1.5,
        letterSpacing: "-0.72px"
      }, {
        fontSize: "24px",
        lineHeight: 1.5,
        letterSpacing: "-0.72px"
      }]
    },
    homeH5: {
      fontFamily: "var(--fontFamily, ".concat(WHITNEY_FONT, ")"),
      fontWeight: 700,
      fontSize: "18px",
      lineHeight: 1.5,
      overrides: [{
        fontSize: "18px",
        lineHeight: 1.5
      }, {
        fontSize: "18px",
        lineHeight: 1.5
      }]
    },
    homeH6: {
      fontFamily: "var(--fontFamily, ".concat(WHITNEY_FONT, ")"),
      fontWeight: 600,
      fontSize: "16px",
      lineHeight: 1.1,
      overrides: [{
        fontSize: "16px",
        lineHeight: 1.1
      }, {
        fontSize: "16px",
        lineHeight: 1.1
      }]
    },
    homeOverline: {
      fontFamily: "var(--fontFamily, ".concat(WHITNEY_FONT, ")"),
      fontWeight: 600,
      fontSize: "16px",
      lineHeight: 1.1,
      overrides: [{
        fontSize: "16px",
        lineHeight: 1.1
      }, {
        fontSize: "16px",
        lineHeight: 1.1
      }]
    },
    homePXXL: {
      fontFamily: "var(--fontFamily, ".concat(WHITNEY_FONT, ")"),
      fontWeight: 400,
      fontSize: "24px",
      lineHeight: 1.5,
      overrides: [{
        fontSize: "24px",
        lineHeight: 1.5
      }, {
        fontSize: "24px",
        lineHeight: 1.5
      }]
    },
    homePXXLBold: {
      fontFamily: "var(--fontFamily, ".concat(WHITNEY_FONT, ")"),
      fontWeight: 600,
      fontSize: "24px",
      lineHeight: 1.5,
      overrides: [{
        fontSize: "24px",
        lineHeight: 1.5
      }, {
        fontSize: "24px",
        lineHeight: 1.5
      }]
    },
    homePExtraLarge: {
      fontFamily: "var(--fontFamily, ".concat(WHITNEY_FONT, ")"),
      fontWeight: 400,
      fontSize: "20px",
      lineHeight: 1.5,
      overrides: [{
        fontSize: "20px",
        lineHeight: 1.5
      }, {
        fontSize: "20px",
        lineHeight: 1.5
      }]
    },
    homePExtraLargeBold: {
      fontFamily: "var(--fontFamily, ".concat(WHITNEY_FONT, ")"),
      fontWeight: 600,
      fontSize: "20px",
      lineHeight: 1.5,
      overrides: [{
        fontSize: "20px",
        lineHeight: 1.5
      }, {
        fontSize: "20px",
        lineHeight: 1.5
      }]
    },
    homePLarge: {
      fontFamily: "var(--fontFamily, ".concat(WHITNEY_FONT, ")"),
      fontWeight: 400,
      fontSize: "18px",
      lineHeight: 1.5,
      overrides: [{
        fontSize: "18px",
        lineHeight: 1.5
      }, {
        fontSize: "18px",
        lineHeight: 1.5
      }]
    },
    homePLargeBold: {
      fontFamily: "var(--fontFamily, ".concat(WHITNEY_FONT, ")"),
      fontWeight: 600,
      fontSize: "18px",
      lineHeight: 1.5,
      overrides: [{
        fontSize: "18px",
        lineHeight: 1.5
      }, {
        fontSize: "18px",
        lineHeight: 1.5
      }]
    },
    homePMedium: {
      fontFamily: "var(--fontFamily, ".concat(WHITNEY_FONT, ")"),
      fontWeight: 400,
      fontSize: "16px",
      lineHeight: 1.5,
      overrides: [{
        fontSize: "16px",
        lineHeight: 1.5
      }, {
        fontSize: "16px",
        lineHeight: 1.5
      }]
    },
    homePMediumBold: {
      fontFamily: "var(--fontFamily, ".concat(WHITNEY_FONT, ")"),
      fontWeight: 600,
      fontSize: "16px",
      lineHeight: 1.5,
      overrides: [{
        fontSize: "16px",
        lineHeight: 1.5
      }, {
        fontSize: "16px",
        lineHeight: 1.5
      }]
    },
    homePSmall: {
      fontFamily: "var(--fontFamily, ".concat(WHITNEY_FONT, ")"),
      fontWeight: 400,
      fontSize: "14px",
      lineHeight: 1.5,
      overrides: [{
        fontSize: "14px",
        lineHeight: 1.5
      }, {
        fontSize: "14px",
        lineHeight: 1.5
      }]
    },
    homePSmallBold: {
      fontFamily: "var(--fontFamily, ".concat(WHITNEY_FONT, ")"),
      fontWeight: 600,
      fontSize: "14px",
      lineHeight: 1.5,
      overrides: [{
        fontSize: "14px",
        lineHeight: 1.5
      }, {
        fontSize: "14px",
        lineHeight: 1.5
      }]
    }
  },
  breakpoints: [
  /** Mobile - default styles */

  /** Tablet */
  breakpoints.up("sm"),
  /** Laptop */
  breakpoints.up("lg")]
});
var typography = createTypography({
  typography: {
    /** Fluid - h1Hero */
    h1Hero: {
      fontFamily: "var(--fontFamily, ".concat(WHITNEY_FONT, ")"),
      fontWeight: 700,
      fontSize: "40px",
      lineHeight: 1.3,
      letterSpacing: "-1.2px",
      overrides: [{
        fontSize: "56px",
        lineHeight: 1.3,
        letterSpacing: "-2.24px"
      }, {
        fontSize: "64px",
        lineHeight: 1.3,
        letterSpacing: "-2.56px"
      }]
    },

    /** Fluid - h1 */
    h1: {
      fontFamily: "var(--fontFamily, ".concat(WHITNEY_FONT, ")"),
      fontWeight: 700,
      fontSize: "34px",
      lineHeight: 1.25,
      letterSpacing: "-1.02px",
      overrides: [{
        fontSize: "40px",
        lineHeight: 1.2,
        letterSpacing: "-1.6px"
      }, {
        fontSize: "48px",
        lineHeight: 1.1,
        letterSpacing: "-1.92px"
      }]
    },

    /** Fluid - h2 */
    h2: {
      fontFamily: "var(--fontFamily, ".concat(WHITNEY_FONT, ")"),
      fontWeight: 600,
      fontSize: "28px",
      lineHeight: 1.3,
      letterSpacing: "-0.56px",
      overrides: [{
        fontSize: "32px",
        lineHeight: 1.3,
        letterSpacing: "-0.64px"
      }, {
        fontSize: "32px",
        lineHeight: 1.3,
        letterSpacing: "-0.64px"
      }]
    },

    /** Fluid - h3 */
    h3: {
      fontFamily: "var(--fontFamily, ".concat(WHITNEY_FONT, ")"),
      fontWeight: 500,
      fontSize: "24px",
      lineHeight: 1.3,
      overrides: [{
        fontSize: "24px",
        lineHeight: 1.3
      }, {
        fontSize: "24px",
        lineHeight: 1.3
      }]
    },

    /** Fluid - h4 */
    h4: {
      fontFamily: "var(--fontFamily, ".concat(WHITNEY_FONT, ")"),
      fontWeight: 500,
      fontSize: "18px",
      lineHeight: 1.5,
      overrides: [{
        fontSize: "18px",
        lineHeight: 1.5
      }, {
        fontSize: "18px",
        lineHeight: 1.5
      }]
    },

    /** Fluid - h5 */
    h5: {
      fontFamily: "var(--fontFamily, ".concat(WHITNEY_FONT, ")"),
      fontWeight: 600,
      fontSize: "18px",
      lineHeight: 1.5,
      overrides: [{
        fontSize: "18px",
        lineHeight: 1.5
      }, {
        fontSize: "18px",
        lineHeight: 1.5
      }]
    },

    /** Fluid - h6 */
    h6: {
      fontFamily: "var(--fontFamily, ".concat(WHITNEY_FONT, ")"),
      fontWeight: 500,
      fontSize: "16px",
      lineHeight: 1.1,
      overrides: [{
        fontSize: "16px",
        lineHeight: 1.1
      }, {
        fontSize: "16px",
        lineHeight: 1.1
      }]
    },

    /** Fluid - overline */
    overline: {
      fontFamily: "var(--fontFamily, ".concat(WHITNEY_FONT, ")"),
      fontWeight: 600,
      fontSize: "16px",
      lineHeight: 1.1,
      overrides: [{
        fontSize: "16px",
        lineHeight: 1.1
      }, {
        fontSize: "16px",
        lineHeight: 1.1
      }]
    },

    /** Fluid - pExtraLarge */
    pExtraLarge: {
      fontFamily: "var(--fontFamily, ".concat(WHITNEY_FONT, ")"),
      fontWeight: 400,
      fontSize: "18px",
      lineHeight: 1.5,
      overrides: [{
        fontSize: "18px",
        lineHeight: 1.5
      }, {
        fontSize: "20px",
        lineHeight: 1.5
      }]
    },

    /** Fluid - pExtraLargeBold */
    pExtraLargeBold: {
      fontFamily: "var(--fontFamily, ".concat(WHITNEY_FONT, ")"),
      fontWeight: 600,
      fontSize: "18px",
      lineHeight: 1.5,
      overrides: [{
        fontSize: "18px",
        lineHeight: 1.5
      }, {
        fontSize: "20px",
        lineHeight: 1.5
      }]
    },

    /** Fluid - pLarge */
    pLarge: {
      fontFamily: "var(--fontFamily, ".concat(WHITNEY_FONT, ")"),
      fontWeight: 400,
      fontSize: "16px",
      lineHeight: 1.5,
      overrides: [{
        fontSize: "16px",
        lineHeight: 1.5
      }, {
        fontSize: "18px",
        lineHeight: 1.5
      }]
    },

    /** Fluid - pLargeBold */
    pLargeBold: {
      fontFamily: "var(--fontFamily, ".concat(WHITNEY_FONT, ")"),
      fontWeight: 600,
      fontSize: "16px",
      lineHeight: 1.5,
      overrides: [{
        fontSize: "16px",
        lineHeight: 1.5
      }, {
        fontSize: "18px",
        lineHeight: 1.5
      }]
    },

    /** Fluid - pMedium */
    pMedium: {
      fontFamily: "var(--fontFamily, ".concat(WHITNEY_FONT, ")"),
      fontWeight: 400,
      fontSize: "14px",
      lineHeight: 1.5,
      overrides: [{
        fontSize: "14px",
        lineHeight: 1.5
      }, {
        fontSize: "16px",
        lineHeight: 1.5
      }]
    },

    /** Fluid - pMediumBold */
    pMediumBold: {
      fontFamily: "var(--fontFamily, ".concat(WHITNEY_FONT, ")"),
      fontWeight: 600,
      fontSize: "14px",
      lineHeight: 1.5,
      overrides: [{
        fontSize: "14px",
        lineHeight: 1.5
      }, {
        fontSize: "16px",
        lineHeight: 1.5
      }]
    },

    /** Fluid - pSmall */
    pSmall: {
      fontFamily: "var(--fontFamily, ".concat(WHITNEY_FONT, ")"),
      fontWeight: 400,
      fontSize: "12px",
      lineHeight: 1.5,
      overrides: [{
        fontSize: "12px",
        lineHeight: 1.5
      }, {
        fontSize: "14px",
        lineHeight: 1.5
      }]
    },

    /** Fluid - pSmallBold */
    pSmallBold: {
      fontFamily: "var(--fontFamily, ".concat(WHITNEY_FONT, ")"),
      fontWeight: 600,
      fontSize: "12px",
      lineHeight: 1.5,
      overrides: [{
        fontSize: "12px",
        lineHeight: 1.5
      }, {
        fontSize: "14px",
        lineHeight: 1.5
      }]
    },

    /** ButtonText styles - button text large */
    buttonTextLarge: {
      fontFamily: "var(--fontFamily, ".concat(WHITNEY_FONT, ")"),
      fontWeight: 600,
      fontSize: "18px",
      lineHeight: 24 / 18
    },

    /** ButtonText styles - button text medium */
    buttonTextMedium: {
      fontFamily: "var(--fontFamily, ".concat(WHITNEY_FONT, ")"),
      fontWeight: 600,
      fontSize: "16px",
      lineHeight: 24 / 16
    },

    /** ButtonText styles - button text small */
    buttonTextSmall: {
      fontFamily: "var(--fontFamily, ".concat(WHITNEY_FONT, ")"),
      fontWeight: 600,
      fontSize: "14px",
      lineHeight: 16 / 14
    },

    /** Basic - text bold */
    textBold: {
      fontFamily: "var(--fontFamily, ".concat(WHITNEY_FONT, ")"),
      fontWeight: 600,
      fontSize: "16px",
      lineHeight: 16 / 16
    },

    /** Basic - text regular small */
    textRegularSmall: {
      fontFamily: "var(--fontFamily, ".concat(WHITNEY_FONT, ")"),
      fontWeight: 400,
      fontSize: "14px",
      lineHeight: 16 / 14
    },

    /** Basic - text bold small */
    textBoldSmall: {
      fontFamily: "var(--fontFamily, ".concat(WHITNEY_FONT, ")"),
      fontWeight: 500,
      fontSize: "14px",
      lineHeight: 16 / 14
    },

    /** Basic - text tiny */
    textTiny: {
      fontFamily: "var(--fontFamily, ".concat(WHITNEY_FONT, ")"),
      fontWeight: 500,
      fontSize: "12px",
      lineHeight: 16 / 12
    },

    /** Supporting - button */
    buttonLarge: {
      fontFamily: "var(--fontFamily, ".concat(WHITNEY_FONT, ")"),
      fontWeight: 500,
      fontSize: "16px",
      lineHeight: 20 / 16
    },

    /** Supporting - button small */
    buttonSmall: {
      fontFamily: "var(--fontFamily, ".concat(WHITNEY_FONT, ")"),
      fontWeight: 500,
      fontSize: "14px",
      lineHeight: 16 / 14
    },

    /** Supporting - form input */
    formInput: {
      fontFamily: "var(--fontFamily, ".concat(WHITNEY_FONT, ")"),
      fontWeight: 400,
      fontSize: "16px",
      lineHeight: 20 / 16
    },

    /** Supporting - form label */
    formLabel: {
      fontFamily: "var(--fontFamily, ".concat(WHITNEY_FONT, ")"),
      fontWeight: 500,
      fontSize: "16px",
      lineHeight: 20 / 16
    },

    /** Supporting - form hint */
    formHint: {
      fontFamily: "var(--fontFamily, ".concat(WHITNEY_FONT, ")"),
      fontWeight: 400,
      fontSize: "14px",
      lineHeight: 16 / 14
    },

    /** Supporting - code */
    code: {
      fontFamily: ROBOTO_MONO_FONT,
      fontWeight: 500,
      fontSize: "12px",
      lineHeight: 20 / 12
    }
  },
  breakpoints: [
  /** Mobile - default styles */

  /** tablet */
  breakpoints.up("sm"),
  /** Laptop */
  breakpoints.up("lg")]
});
var palette = {
  brand: {
    segmentGreen: {
      '100': "#F1FAF6",
      '200': "#D5EFE5",
      '300': "#ACDFCC",
      '400': "#82CFB2",
      '500': "#52BD94",
      '600': "#068465",
      '700': "#317159",
      '800': "#214C3B",
      '900': "#10261E"
    },
    boysenberry: {
      '100': "#F2F1FA",
      '200': "#D9D6EF",
      '300': "#B3AEDE",
      '400': "#857BDE",
      '500': "#6156BB",
      '600': "#4E4596",
      '700': "#372F75",
      '800': "#27224B",
      '900': "#131125"
    },
    darkAcai: {
      '100': "#F2F1F3",
      '200': "#EDEBEF",
      '300': "#C9C3CE",
      '400': "#A89CB4",
      '500': "#5C4D6B",
      '600': "#352446",
      '700': "#1C0732",
      '800': "#130522",
      '900': "#06010A"
    }
  },
  neutrals: {
    white: {
      '100': "#FFFFFF"
    },
    cloud: {
      '100': "#F7F8FC"
    },
    concrete: {
      '100': "#F2F3F6"
    },
    black: {
      '100': "#000000"
    }
  },
  status: {
    success: {
      '100': "#52BD94",
      '200': "#43BA8D"
    },
    warning: {
      '100': "#F0D266",
      '200': "#F0D05B"
    },
    error: {
      '100': "#DE4557",
      '200': "#D63D4F"
    }
  },
  twilio: {
    mint: {
      '100': "#6ADDB2",
      '20': "#D9F1E8",
      '10': "#EAFAF4"
    },
    blue: {
      '10': "#EBF4FF",
      '20': "#CCE4FF",
      '30': "#99CDFF",
      '40': "#66B3FF",
      '50': "#008CFF",
      '60': "#0263E0",
      '70': "#043CB5",
      '80': "#001489",
      '90': "#030B5D",
      '100': "#06033A"
    },
    green: {
      '10': "#F1FAF6",
      '20': "#DFFAEE",
      '30': "#BBEDD7",
      '40': "#91E7C3",
      '50': "#6DD7AA",
      '60': "#52BD94",
      '70': "#068465",
      '80': "#056A50",
      '90': "#214C3B",
      '100': "#10261E"
    },
    boysenberry: {
      '10': "#F8F7FF",
      '20': "#E9E7FF",
      '30': "#D4D0FF",
      '40': "#B1AAF3",
      '50': "#857BDE",
      '60': "#6156BB",
      '70': "#4F44AA",
      '80': "#3D3293",
      '90': "#131125",
      '100': "#0E0C1F"
    },
    gray: {
      '10': "#F4F4F6",
      '20': "#E1E3EA",
      '30': "#CACDD8",
      '40': "#AEB2C1",
      '50': "#8891AA",
      '60': "#606B85",
      '70': "#4B5671",
      '80': "#394762",
      '90': "#1F304C",
      '100': "#121C2D"
    }
  },
  cdpWeek2023: {
    gray: {
      '10': "#F4F4F6",
      '20': "#E1E3EA",
      '30': "#CACDD8",
      '40': "#AEB2C1",
      '60': "#606B85",
      '80': "#394762",
      '90': "#1F304C",
      '100': "#121C2D"
    },
    blue: {
      '40': "#66B3FF",
      '50': "#008CFF",
      '60': "#0263E0",
      '80': "#0045B0",
      '90': "#002185"
    },
    saffron: {
      '100': "#F2BE5A"
    },
    mint: {
      '100': "#6ADDB2"
    },
    red: {
      '100': "#F22F46"
    }
  },
  code: {
    grass: {
      '10': "#52BD95"
    },
    sun: {
      '10': "#F0D266"
    },
    gray: {
      '10': "#737A96"
    },
    stone: {
      '10': "#D5DAF0"
    },
    lavender: {
      '10': "#C2B9F5"
    },
    mint: {
      '10': "#B9F5C2"
    },
    buttermilk: {
      '10': "#F5E0B9"
    },
    sky: {
      '10': "#86AFFF"
    },
    rose: {
      '10': "#F5B9D6"
    },
    watermelon: {
      '10': "#EE5C6D"
    }
  },
  logo: {
    green: {
      '10': "#6ADDB2"
    }
  }
};
var theme = {
  palette: palette,
  typography: _objectSpread(_objectSpread({}, typography), homeTypography),
  shadows: {
    0: "none",
    1: "0px 6px 12px rgba(13, 21, 55, 0.1)",
    2: "0px 15px 35px rgba(13, 21, 55, 0.08)",
    3: "0px 30px 60px -24px rgba(0, 0, 0, 0.1)",
    4: "0px 25px 50px rgba(13, 21, 55, 0.15)",
    5: "0px 40px 80px rgba(13, 21, 55, 0.4)",
    6: "0px 75px 45px -65px rgba(0, 0, 0, 0.5)",
    7: "0px 72px 64px -48px rgba(19, 5, 34, 0.2)",
    8: "0px 97px 60px -75px rgba(19, 22, 35, 0.25)"
  },
  breakpoints: breakpoints
};
export default theme;