import { rehydrateFirstUrlCookies, rehydrateUtmCookies, identify, track, trackPage, getDeserializedParamsFromCookies } from './scripts';

var useTracking = function useTracking() {
  return {
    rehydrateFirstUrlCookies: rehydrateFirstUrlCookies,
    rehydrateUtmCookies: rehydrateUtmCookies,
    getDeserializedParamsFromCookies: getDeserializedParamsFromCookies,
    track: track,
    identify: identify,
    trackPage: trackPage
  };
};

export default useTracking;