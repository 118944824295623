import _slicedToArray from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/slicedToArray.js";
import { getCookie, setCookie } from './cookies';
var SEG_UTM_COOKIE = "seg_attr_utm";
var SEG_FIRST_COOKIE = "seg_first_utm";
var GCLID_COOKIE = "gclid";
export var getDeserializedParamsFromCookies = function getDeserializedParamsFromCookies() {
  var utm = deserializeCookies(getCookie(SEG_UTM_COOKIE));
  var gclid = deserializeCookies(getCookie(GCLID_COOKIE), GCLID_COOKIE);
  return "".concat(utm).concat(gclid && utm ? "&" : "").concat(gclid);
};
export var rehydrateUtmCookies = function rehydrateUtmCookies() {
  try {
    var params = serializeSearchString();
    var cookieUtm = getCookie(SEG_UTM_COOKIE);
    var cookieFirstUtm = getCookie(SEG_FIRST_COOKIE);
    var queryUtmParams = Object.entries(params).reduce(function (acc, _ref) {
      var _ref2 = _slicedToArray(_ref, 2),
          key = _ref2[0],
          value = _ref2[1];

      if (/^utm_/i.test(key)) {
        acc[key] = value;
      }

      return acc;
    }, {});

    if (!cookieFirstUtm) {
      // Set first UTM params if this is the first page load for session
      setCookie(SEG_FIRST_COOKIE, JSON.stringify(queryUtmParams), 30);
    } // Cookie glcid if present


    if (params.gclid) {
      setCookie(GCLID_COOKIE, params.gclid, 30);
    }

    var cookieUtmParams = JSON.parse(cookieUtm || "null"); // This cookie gets reset every time there are new UTM params that do not match the cookie

    if (Object.keys(queryUtmParams).length > 0 && (JSON.stringify(cookieUtmParams) !== JSON.stringify(queryUtmParams) || !cookieUtmParams)) {
      setCookie(SEG_UTM_COOKIE, JSON.stringify(queryUtmParams), 30);
    }
  } catch (e) {
    console.warn("Error on trying to rehydrate and parse cookie data: ".concat(e, "."));
  }
};
export var rehydrateFirstUrlCookies = function rehydrateFirstUrlCookies() {
  var cookieFirstURL = getCookie("seg_first_url");

  if (!cookieFirstURL) {
    setCookie("seg_first_url", window.location.href, 30);
  }
};
export var getUtmParams = function getUtmParams() {
  var cookieUtm = getCookie(SEG_UTM_COOKIE);
  var cookieUtmParams = JSON.parse(cookieUtm || "null");
  return cookieUtmParams;
};

var serializeSearchString = function serializeSearchString() {
  var queryString = window.location.search.replace(/^\?/, "");
  var pairs = queryString.split("&");
  var params = pairs.reduce(function (acc, pair) {
    var parts = pair.split("=");
    acc[decodeURIComponent(parts[0])] = decodeURIComponent(parts[1]) || parts[1] || undefined;
    return acc;
  }, {});
  return params;
};

var deserializeKeyValue = function deserializeKeyValue(data) {
  var deserialized = "";

  for (var _key in data) {
    deserialized += "".concat(_key, "=").concat(encodeURIComponent(data[_key]), "&");
  }

  return deserialized.substring(0, deserialized.length - 1);
};

var deserializeCookies = function deserializeCookies(serializedCookie) {
  var key = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : "key";
  if (!serializedCookie) return "";

  try {
    return deserializeKeyValue(JSON.parse(serializedCookie));
  } catch (_err) {
    return "".concat(key, "=").concat(serializedCookie);
  }
};