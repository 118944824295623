import _defineProperty from "/workdir/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

var purgeTraitFromPII = function purgeTraitFromPII(traits, trait) {
  var copy = _objectSpread({}, traits);

  if (copy[trait]) {
    delete copy[trait];
  }

  return copy;
};

var purgeFormPII = function purgeFormPII(traits) {
  var copy = _objectSpread({}, traits);

  var FORM_PII = ["email", "name", "phone", "company", "companySize", "jobTitle", "monthlyTraffic", "timeline", "first_name", "last_name", "firstName", "lastName"];
  var purgedObj = FORM_PII.reduce(function (acc, trait) {
    acc = purgeTraitFromPII(acc, trait);
    return acc;
  }, copy);
  return purgedObj;
};

var TOP_LEVEL_TRAITS = ["ajs_user_traits", "mkjs_user_traits", "kl:traits"];
var UNKNOWN_DEPTH_TRAITS = ["optimizely_data$$"];
/**
 * Purges PII from localStorage objects of 1 level depth.
 */

var purgeTopLevelTraitsFromLocalStorage = function purgeTopLevelTraitsFromLocalStorage() {
  TOP_LEVEL_TRAITS.forEach(function (key) {
    var storedUserData = localStorage.getItem(key);

    if (storedUserData) {
      var traits = JSON.parse(storedUserData);
      localStorage.setItem(key, JSON.stringify(purgeFormPII(traits)));
    }
  });
};

var recursivelyPurgePII = function recursivelyPurgePII(obj, isForm) {
  if (obj === null || typeof obj !== "object") return obj;

  if (Array.isArray(obj)) {
    return obj.map(function (item) {
      return recursivelyPurgePII(item, false);
    });
  }

  var purgedObj = isForm ? purgeFormPII(obj) : purgeTraitFromPII(obj, "email");
  var result = Object.keys(purgedObj).reduce(function (acc, key) {
    acc[key] = recursivelyPurgePII(purgedObj[key], key === "form");
    return acc;
  }, {});
  return result;
};

var isKeyUnknownDepthTrait = function isKeyUnknownDepthTrait(key) {
  return UNKNOWN_DEPTH_TRAITS.some(function (trait) {
    return key.startsWith(trait);
  });
};
/**
 * Purges PII from localStorage objects of unknown depth.
 * Trait 'email' purged on any level
 * Other PII traits purged only if parent is 'form'
 */


var purgeUnknownDepthTraitsFromLocalStorage = function purgeUnknownDepthTraitsFromLocalStorage() {
  for (var i = 0; i < localStorage.length; i++) {
    var key = localStorage.key(i) || "";

    if (isKeyUnknownDepthTrait(key)) {
      var value = localStorage.getItem(key);
      var parsed = value ? JSON.parse(value) : null; // ?

      var newItem = recursivelyPurgePII(parsed, false); // ?

      localStorage.setItem(key, JSON.stringify(newItem));
    }
  }
};

export var purgePIIFromLocalStorage = function purgePIIFromLocalStorage() {
  purgeTopLevelTraitsFromLocalStorage();
  purgeUnknownDepthTraitsFromLocalStorage();
};